import $ from "jquery";
$('document').ready(function(){
    /**
     * Open modal to softdelete request data
     */
    $('#modalUploadDelete').modal('open', {dismissable: false});
});
var fileUploaded = function() {
    // check if file is uploaded
    $(function(){
        $("#modelFile").on('change', function() {
            // get $uploads_done value
            const uploads_done = $("#uploads_done").val();
            // get $uploads_max value
            const uploads_max = $("#uploads_max").val();
            // get $has_valid_request value
            const has_valid_request = $("#has_valid_request").val();


            if (uploads_done === uploads_max) {
                alert("Hai raggiunto il massimo di file caricabili!."); // TODO change to modal
            } else if(has_valid_request) {
                $("#uploadModelFile").prop('disabled', false);
            } else {
                // enable button serviceUploadBtn
                $("#serviceUploadBtn").prop('disabled', false);
            }
        });
        // onclick on button uploadModelFile
        $("#uploadModelFile").on('click', function() {

            //get file content
            const file = $("#modelFile").prop('files')[0];
            let formData = new FormData ();
            formData.append("modelFile",file);
            formData.append("request_id",$("#id_request").val());
            formData.append("company_id",$("#company_id").val());
            formData.append("type",$("#type").val());

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                url: "api/scoring/uploadModelFile",
                type: "POST",
                data: formData,
                processData: false,
                contentType: false,
                success: function (data) {
                    var response = data;
                    if (response.success) {
                        $('#modalUploadSuccess .modal-content .modal-info').text(
                            response.custom_message ?? ""
                        );
                        $('#modalUploadSuccess').modal('open');
                    } else {
                        $('#modalUploadError .modal-content .modal-info').text(
                            response.custom_message ?? ""
                        );
                        $('#modalUploadError').modal('open');
                    }
                },
                error: function (data) {
                    console.log('error ',data);
                    $('#modalUploadError .modal-content .modal-info')
                        .text(data.responseJSON.custom_message ?? 'Si è verificato un errore durante il processo di upload del file');
                    $('#modalUploadError').modal('open');
                },
            });
        });

    });

}
document.addEventListener("DOMContentLoaded", fileUploaded);
