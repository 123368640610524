import * as echarts from "echarts/dist/echarts.js";
import $ from "jquery";

var loadData = function () {
    if (window.location.pathname === "/dashboard")
        $.ajax({
            url: "/dashboard/getInnolvaData",
            type: "GET",
            success: function (data) {
                var response = data.data;

                // Counters
                $("#maxAllowedScoringServices").text(
                    data.maxAllowedScoringServices
                );
                $("#innolvaRequestCount").text(data.innolvaRequestCount);
                // Reability
                if (
                    response.descrizione_affidabilita &&
                    response.valore_affidabilita
                ) {
                    setReliability(response);
                    $(".company-reiability").removeClass(
                        "company-reiability__no-data"
                    );
                }
                // Risk
                if (response.rischio_azienda && response.rischio_settore) {
                    setCompanyGauge(response);
                    setSectorGauge(response);
                    $(".company-risk").removeClass("company-risk__no-data");
                }
                // Economics
                if (
                    response.score_valore &&
                    response.score_finanziario_valore_1 &&
                    response.score_finanziario_valore_2 &&
                    response.score_finanziario_valore_3 &&
                    response.score_finanziario_valore_4 &&
                    response.score_finanziario_valore_5
                ) {
                    setEconomicBar(response);
                    setEconomicGauge(response);
                    $(".economic-score").removeClass("economic-score__no-data");
                }
            },
            error: function (data) {
                // Counters
                $("#maxAllowedScoringServices").text("0");
                $("#innolvaRequestCount").text("0");
                // Reability
                $(".company-reiability").addClass(
                    "company-reiability__no-data"
                );
                // Risk
                $(".company-risk").addClass("company-risk__no-data");
                // Economics
                $(".economic-score").addClass("economic-score__no-data");
            },
        });
};

var setReliability = function (data) {
    $("#descrizione_affidabilita").text(
        "AFFIDABILITÀ " + data.descrizione_affidabilita
    );
    $("#valore_affidabilita").text(data.valore_affidabilita);

    $(".company-reiability").removeClass(
        "company-reiability__green company-reiability__yellow company-reiability__red"
    );

    switch (data.semaforo_affidabilita) {
        case "V":
            $(".company-reiability").addClass("company-reiability__green");
            break;
        case "G":
            $(".company-reiability").addClass("company-reiability__yellow");
            break;
        case "R":
            $(".company-reiability").addClass("company-reiability__red");
            break;
        default:
            // Do nothing
            break;
    }
};

var setEconomicBar = function (data) {
    if (!document.getElementById("economic-bar")) return;

    var myChart = echarts.init(document.getElementById("economic-bar"));

    var option = {
        tooltip: {
            show: false,
            enabled: false,
        },
        grid: {
            left: "3%",
            right: "15%",
            bottom: "3%",
            containLabel: true,
        },
        xAxis: {
            type: "value",
            name: "OTTIMALE",
            nameLocation: "end",
            nameTextStyle: {
                padding: [5, 0, 0, -5],
                verticalAlign: "top",
                fontSize: 15,
                color: "black",
            },
            axisLine: {
                show: true,
                onZeroAxisIndex: 0,
            },
            axisTick: {
                show: true,
                length: 8,
            },
            splitLine: {
                show: false,
            },
            axisLabel: {
                padding: [10, 0, 0, 0],
                color: "black",
            },
            splitNumber: 10,
        },
        yAxis: {
            type: "category",
            name: "CRITICO",
            nameLocation: "start",
            tooltip: {
                show: false,
            },
            nameTextStyle: {
                padding: [-10, 0, 0, -80],
                verticalAlign: "start",
                fontSize: 15,
                color: "black",
            },
            data: [
                data.score_finanziario_desc_1,
                data.score_finanziario_desc_2,
                data.score_finanziario_desc_3,
                data.score_finanziario_desc_4,
                data.score_finanziario_desc_5,
            ],
        },
        series: [
            {
                name: "rating",
                type: "bar",
                stack: "total",
                color: "#33456b",
                tooltip: {
                    show: false,
                },
                label: {
                    show: true,
                },
                data: [
                    data.score_finanziario_valore_1,
                    data.score_finanziario_valore_2,
                    data.score_finanziario_valore_3,
                    data.score_finanziario_valore_4,
                    data.score_finanziario_valore_5,
                ],
            },
            {
                name: "off-rating",
                type: "bar",
                stack: "total",
                color: "#F0F8FF",
                tooltip: {
                    show: false,
                },
                label: {
                    show: true,
                },
                data: [
                    100 - data.score_finanziario_valore_1,
                    100 - data.score_finanziario_valore_2,
                    100 - data.score_finanziario_valore_3,
                    100 - data.score_finanziario_valore_4,
                    100 - data.score_finanziario_valore_5,
                ],
            },
        ],
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
};
var setEconomicGauge = function (data) {
    if (!document.getElementById("economic-gauge")) return;
    var parsedScore = data.score_valore;
    if (isNaN(data.score_valore) || data.score_valore === 0) parsedScore = 0;
    var dataColor =
        data.score_semaforo === "R"
            ? "#FF2100"
            : data.score_semaforo === "G"
            ? "#F7DE22"
            : "#04B300";

    var myChart = echarts.init(document.getElementById("economic-gauge"));
    var option = {
        series: [
            {
                color: dataColor,
                type: "gauge",
                progress: {
                    show: true,
                    width: 8,
                },
                axisLine: {
                    lineStyle: {
                        width: 8,
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    length: 5,
                    lineStyle: {
                        width: 1,
                        color: "#999",
                    },
                },
                axisLabel: {
                    distance: 15,
                    color: "#999",
                    fontSize: 10,
                },
                anchor: {
                    show: true,
                    showAbove: true,
                    size: 15,
                    itemStyle: {
                        borderWidth: 6,
                        borderColor: dataColor,
                    },
                },
                title: {
                    show: false,
                },
                detail: {
                    valueAnimation: true,
                    fontSize: 50,
                    offsetCenter: [0, "70%"],
                    color: dataColor,
                },
                data: [
                    {
                        value: parsedScore,
                    },
                ],
            },
        ],
    };
    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
};

var setCompanyGauge = function (data) {
    if (!document.getElementById("company-gauge")) return;
    var myChart = echarts.init(document.getElementById("company-gauge"));

    //$("#rischio_azienda_semaforo").text(data.rischio_azienda_semaforo);
    var dataColor =
        data.rischio_azienda_semaforo === "R"
            ? "#FF2100"
            : data.rischio_azienda_semaforo === "G"
            ? "#F7DE22"
            : "#04B300";

    var option = {
        series: [
            {
                color: dataColor,
                type: "gauge",
                radius: "100%",
                center: ["50%", "50%"],
                progress: {
                    show: true,
                    width: 8,
                },
                axisLine: {
                    lineStyle: {
                        width: 8,
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    length: 5,
                    lineStyle: {
                        width: 1,
                        color: "#999",
                    },
                },
                axisLabel: {
                    distance: 15,
                    color: "#999",
                    fontSize: 10,
                },
                anchor: {
                    show: true,
                    showAbove: true,
                    size: 15,
                    itemStyle: {
                        borderWidth: 6,
                        borderColor: dataColor,
                    },
                },
                title: {
                    show: false,
                },
                detail: {
                    valueAnimation: true,
                    fontSize: 50,
                    offsetCenter: [0, "70%"],
                    color: dataColor,
                },
                data: [
                    {
                        value: data.rischio_azienda,
                    },
                ],
            },
        ],
    };
    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
};

var setSectorGauge = function (data) {
    if (!document.getElementById("sector-gauge")) return;
    var myChart = echarts.init(document.getElementById("sector-gauge"));
    var dataColor =
        data.rischio_settore_semaforo === "R"
            ? "#FF2100"
            : data.rischio_settore_semaforo === "G"
            ? "#F7DE22"
            : "#04B300";
    //$("#rischio_settore_semaforo").text(data.rischio_settore_semaforo);
    var option = {
        series: [
            {
                color: dataColor,
                type: "gauge",
                radius: "100%",
                center: ["50%", "50%"],
                progress: {
                    show: true,
                    width: 8,
                },
                axisLine: {
                    lineStyle: {
                        width: 8,
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    length: 5,
                    lineStyle: {
                        width: 1,
                        color: "#999",
                    },
                },
                axisLabel: {
                    distance: 15,
                    color: "#999",
                    fontSize: 10,
                },
                anchor: {
                    show: true,
                    showAbove: true,
                    size: 15,
                    itemStyle: {
                        borderWidth: 6,
                        borderColor: dataColor,
                    },
                },
                title: {
                    show: false,
                },
                detail: {
                    valueAnimation: true,
                    fontSize: 50,
                    offsetCenter: [0, "70%"],
                    color: dataColor,
                },
                data: [
                    {
                        value: data.rischio_settore,
                    },
                ],
            },
        ],
    };
    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
};
if (window.userRole !== 'admin_plus' && window.userRole !== 'admin_cciaa')
    document.addEventListener("DOMContentLoaded", loadData);
