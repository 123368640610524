import $ from "jquery";
$(document).ready(function () {
    $(function () {
        $.ajax({
            type: "GET",
            url: "/select_provice",
            success: function (response) {
                var countryArray = response;
                var dataCountry = {};
                for (var i = 0; i < countryArray.length; i++) {
                    //console.log(countryArray[i].name);
                    dataCountry[countryArray[i].nome] = countryArray[i].flag; //countryArray[i].flag or null
                }
                $("input.autocomplete").autocomplete({
                    data: dataCountry,
                });
            },
        });
    });
});
