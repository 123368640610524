import $ from "jquery";
var loadData = function() {
    /**
     * Forms
     */
    $('.frmCreateEntity').on('submit',function(e){
        console.info('click su submit form',$(this).find('.frmSubmitButton'));
        $(this).find('.frmSubmitButton').prop('disabled', true);
    });
}
document.addEventListener("DOMContentLoaded", loadData);
