import * as echarts from "echarts/dist/echarts.js";
import $ from "jquery";

var loadData = function () {
    if (window.location.pathname === "/dashboard")
        $.ajax({
            url: "/dashboard/getGhostCFOData",
            type: "GET",
            success: function (data) {
                var response = data.data;
                // Counters
                $("#maxAllowedWarningServices").text(
                    data.maxAllowedWarningServices
                );
                $("#ghostCfoRequestCount").text(data.ghostCfoRequestCount);

                // CR
                if (response.rating_cr_punteggio) {
                    $(".rating-cr").removeClass("rating-cr__no-data");
                    setCRGauge(response);
                }
                // ESA
                if (
                    response.esa_profilo_base &&
                    response.esa_outlook_forward_looking &&
                    response.esa_giudizio_finale
                ) {
                    setEsa(response);
                    $(".alert-system").removeClass("alert-system__no-data");
                }
                // IDC
                if (
                    response.idc_dscr_valore &&
                    response.idc_cndcec_valore &&
                    response.idc_patrimonio_dati_valore
                ) {
                    setIdc(response);
                    $(".crysis-indicator").removeClass(
                        "crysis-indicator__no-data"
                    );
                }
                // MCC
                if (
                    response.mcc_classe &&
                    response.mcc_esito &&
                    response.mcc_score
                ) {
                    setAverageCompanyCredit(response);
                    $(".average-company-credit").removeClass(
                        "average-company-credit__no-data"
                    );
                }
                if(
                    response.eaa_squilibri &&
                    response.eaa_squilibri_colore
                ) {
                    document.getElementById("eaa_squilibri").innerText = response.eaa_squilibri;
                    document.getElementById("eaa_squilibri_container").style.background = response.eaa_squilibri_colore;
                    document.getElementById("eaa_squilibri_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_squilibri_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_squilibri__data-container").classList.add('hide');
                }
                if(
                    response.eaa_profilo_sost_12M &&
                    response.eaa_squilibri_colore
                ) {
                    document.getElementById("eaa_profilo_sost_12M").innerText = response.eaa_profilo_sost_12M;
                    document.getElementById("eaa_profilo_sost_12M_container").style.background = response.eaa_sost_colore;
                    document.getElementById("eaa_profilo_sost_12M_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_profilo_sost_12M_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_profilo_sost_12M__data-container").classList.add('hide');

                }
                if(
                    response.eaa_segnali_allarme &&
                    response.eaa_segnali_allarme_colore
                ) {
                    document.getElementById("eaa_segnali_allarme").innerText = response.eaa_segnali_allarme;
                    document.getElementById("eaa_segnali_allarme_container").style.background = response.eaa_segnali_allarme_colore;
                    document.getElementById("eaa_segnali_allarme_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_segnali_allarme_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_segnali_allarme_data-container").classList.add('hide');
                }
                if(
                    response.eaa_perc_adeguatezza
                ) {
                    createAdeguateAssessmentGraph(response);
                }else{
                    document.getElementById("completedAssessmentGraph__no-data-container").classList.remove('hide');
                    document.getElementById("completedAssessmentGraph").classList.add('hide');
                }

                if(
                    response.eaa_pr_squilibri_eco
                ) {
                    document.getElementById("eaa_pr_squilibri_eco").innerText = response.eaa_pr_squilibri_eco;
                    document.getElementById("eaa_pr_squilibri_eco_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_pr_squilibri_eco_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_pr_squilibri_eco__data-container").classList.add('hide');

                }
                if(
                    response.eaa_pr_squilibri_fin
                ) {
                    document.getElementById("eaa_pr_squilibri_fin").innerText = response.eaa_pr_squilibri_fin;
                    document.getElementById("eaa_pr_squilibri_fin_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_pr_squilibri_fin_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_pr_squilibri_fin__data-container").classList.add('hide');

                }
                if(
                    response.eaa_pr_squilibri_pat
                ) {
                    document.getElementById("eaa_pr_squilibri_pat").innerText = response.eaa_pr_squilibri_pat;
                    document.getElementById("eaa_pr_squilibri_pat_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_pr_squilibri_pat_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_pr_squilibri_pat__data-container").classList.add('hide');

                }
                if(
                    response.eaa_profilo_allarme_inps
                ) {
                    document.getElementById("eaa_profilo_allarme_inps").innerText = response.eaa_profilo_allarme_inps;
                    document.getElementById("eaa_profilo_allarme_inps_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_profilo_allarme_inps_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_profilo_allarme_inps__data-container").classList.add('hide');

                }
                if(
                    response.eaa_profilo_allarme_age
                ) {
                    document.getElementById("eaa_profilo_allarme_age").innerText = response.eaa_profilo_allarme_age;
                    document.getElementById("eaa_profilo_allarme_age_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_profilo_allarme_age_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_profilo_allarme_age__data-container").classList.add('hide');

                }
                if(
                    response.eaa_profilo_allarme_ris
                ) {
                    document.getElementById("eaa_profilo_allarme_ris").innerText = response.eaa_profilo_allarme_ris;
                    document.getElementById("eaa_profilo_allarme_ris_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_profilo_allarme_ris_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_profilo_allarme_ris__data-container").classList.add('hide');

                }
                if(
                    response.eaa_profilo_allarme_retrib
                ) {
                    document.getElementById("eaa_profilo_allarme_retrib").innerText = response.eaa_profilo_allarme_retrib;
                    document.getElementById("eaa_profilo_allarme_retrib_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_profilo_allarme_retrib_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_profilo_allarme_retrib__data-container").classList.add('hide');

                }
                if(
                    response.eaa_profilo_allarme_forn
                ) {
                    document.getElementById("eaa_profilo_allarme_forn").innerText = response.eaa_profilo_allarme_forn;
                    document.getElementById("eaa_profilo_allarme_forn_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_profilo_allarme_forn_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_profilo_allarme_banche__data-container").classList.add('hide');
                }
                if(
                    response.eaa_profilo_allarme_banche
                ) {
                    document.getElementById("eaa_profilo_allarme_banche").innerText = response.eaa_profilo_allarme_banche;
                    document.getElementById("eaa_profilo_allarme_banche_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_profilo_allarme_banche_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_profilo_allarme_banche__data-container").classList.add('hide');

                }
                if(
                    response.eaa_profilo_allarme_inail
                ) {
                    document.getElementById("eaa_profilo_allarme_inail").innerText = response.eaa_profilo_allarme_inail;
                    document.getElementById("eaa_profilo_allarme_inail_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("eaa_profilo_allarme_inail_no-data-container").classList.remove('hide');
                    document.getElementById("eaa_profilo_allarme_inail__data-container").classList.add('hide');

                }

                if(
                    response.tr_fascia
                ) {
                    document.getElementById("tr_fascia").innerText = response.tr_fascia;
                    document.getElementById("tr_fascia_no-data-container").classList.add('hide');
                    document.getElementById("tr_fascia_container").style.background = response.tr_fascia_colore;
                }else{
                    document.getElementById("tr_fascia_no-data-container").classList.remove('hide');
                    document.getElementById("tr_fascia__data-container").classList.add('hide');

                }
                if(
                    response.tr_grado_risanamento
                ) {
                    document.getElementById("tr_grado_risanamento").innerText = response.tr_grado_risanamento;
                    document.getElementById("tr_grado_risanamento_no-data-container").classList.add('hide');
                }else{
                    document.getElementById("tr_grado_risanamento_no-data-container").classList.remove('hide');
                    document.getElementById("tr_grado_risanamento__data-container").classList.add('hide');

                }

                if(
                    response.tr_esito !== null &&
                    response.tr_indice_risanamento
                ) {
                    document.getElementById("tr_esito").innerText = response.tr_esito.toFixed(2).replace('.', ',');
                    document.getElementById("tr_esito-container").style.background = response.tr_esito_colore;


                    document.getElementById("tr_indice_risanamento").innerText = response.tr_indice_risanamento;
                    document.getElementById("tr_esito-rating").style.background = response.tr_esito_colore;

                    document.getElementById("tr_esito__no-data-container").classList.add('hide');
                    document.getElementById("tr_esito__data-container").classList.remove('hide');
                }else{
                    document.getElementById("tr_esito__data-container").classList.add('hide');
                    document.getElementById("tr_esito__no-data-container").classList.remove('hide');
                }

                // Needed to redraw charts after toggling display
                setTimeout(function () {
                    window.dispatchEvent(new Event("resize"));
                }, 500);
            },
            error: function (data) {
                // Counters
                $("#maxAllowedWarningServices").text("0");
                $("#ghostCfoRequestCount").text("0");
                // CR
                $(".rating-cr").addClass("rating-cr__no-data");
                // ESA
                $(".alert-system").addClass("alert-system__no-data");
                // IDC
                $(".crysis-indicator").addClass("crysis-indicator__no-data");
                // Company Credit
                $(".average-company-credit").addClass(
                    "average-company-credit__no-data"
                );
                if ($('#completedAssessmentGraph__no-data-at-all-container').length > 0)
                    document.getElementById("completedAssessmentGraph__no-data-at-all-container").classList.remove('hide');
                if ($('.completedAssessmentData').length > 0)
                    document.getElementsByClassName("completedAssessmentData")[0].classList.add('hide');
                if ($('.completedAssessmentData').length > 0)
                    document.getElementsByClassName("completedAssessmentData")[1].classList.add('hide');
                if ($('#completedAssessmentGraph__no').length > 0)
                    document.getElementById("completedAssessmentGraph__no-data-container").classList.add('hide');

                if ($('#sustainability__no-data-at-all-container').length > 0)
                    document.getElementById("sustainability__no-data-at-all-container").classList.remove('hide');
                if ($('.sustainabilityData').length > 0)
                    document.getElementsByClassName("sustainabilityData")[0].classList.add('hide');
                if ($('.sustainabilityData').length > 0)
                    document.getElementsByClassName("sustainabilityData")[1].classList.add('hide');



                // Needed to redraw charts after toggling display
                setTimeout(function () {
                    window.dispatchEvent(new Event("resize"));
                }, 500);
            },
        });
};

var setAverageCompanyCredit = function (data) {
    $("#mcc_classe").text(data.mcc_classe);
    $("#mcc_esito").text(data.mcc_esito);
    $("#mcc_score").text(data.mcc_score);
    $("#mcc_colore-rating").css("background-color", data.mcc_colore);
    $("#mcc_colore-container").css("background-color", data.mcc_colore);
};
var setEsa = function (data) {
    $("#esa_profilo_base").text(data.esa_profilo_base);
    $("#esa_outlook_forward_looking").text(data.esa_outlook_forward_looking);
    $("#esa_giudizio_finale").text(data.esa_giudizio_finale);

    $("#esa_profilo_base_container").css(
        "background-color",
        data.esa_profilo_base_colore
    );
    $("#esa_outlook_forward_looking_container").css(
        "background-color",
        data.esa_outlook_forward_looking_colore
    );
    $("#esa_giudizio_finale_container").css(
        "background-color",
        data.esa_giudizio_finale_colore
    );
};

var setIdc = function (data) {
    var dsrc = data.idc_dscr_valore.replaceAll(",", "-").replaceAll(".", ",").replaceAll("-", ".");
    $("#idc_dscr_valore").text(dsrc);
    var dsrcSoglia = data.idc_dscr_soglia.replaceAll(",", "-").replaceAll(".", ",").replaceAll("-", ".");
    $("#idc_dscr_soglia").text("SOGLIA: " + dsrcSoglia);


    $("#idc_cndcec_valore").text(data.idc_cndcec_valore.split('.')[0]);
    $("#idc_cndcec_soglia").text("SOGLIA: " + data.idc_cndcec_soglia.split('.')[0]);

    var patrimonio = data.idc_patrimonio_dati_valore.replaceAll(",", "-").replaceAll(".", ",").replaceAll("-", ".");
    $("#idc_patrimonio_dati_valore").text(patrimonio);
    var patrimonioSoglia = data.idc_patrimonio_dati_soglia.replaceAll(",", "-").replaceAll(".", ",").replaceAll("-", ".");
    $("#idc_patrimonio_dati_soglia").text(
        "SOGLIA: " + patrimonioSoglia
    );

    $("#idc_dscr_valore").css("color", data.idc_dscr_colore);
    $("#idc_cndcec_valore").css("color", data.idc_cndcec_colore);
    $("#idc_patrimonio_dati_valore").css(
        "color",
        data.idc_patrimonio_dati_colore
    );

    if (data.idc_dscr_superamento_soglia) {
        $("#idc_dscr_container").css("background-color", "tomato");
    }
    if (data.idc_cndcec_superamento_soglia) {
        $("#idc_cndcec_container").css("background-color", "tomato");
    }
    if (data.idc_patrimonio_dati_superamento_soglia) {
        $("#idc_patrimonio_container").css("background-color", "tomato");
    }
};

var createAdeguateAssessmentGraph = function (response) {
    var chartAssessment = echarts.init(document.getElementById('completedAssessmentGraph'));
    var options;
    options = {
        series: [
            {
                type: 'gauge',
                startAngle: 90,
                endAngle: -270,
                pointer: {
                    show: false
                },
                progress: {
                    show: true,
                    overlap: false,
                    roundCap: true,
                    itemStyle: {
                        color: '#38A738'
                    }
                },
                axisLine: {
                    lineStyle: {
                        width: 20
                    }
                },
                splitLine: {
                    show: false,
                    distance: 0,
                    length: 10
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                    distance: 50
                },
                data: [
                    {
                        value: response.eaa_perc_adeguatezza,
                        name: 'Completato al',
                        title: {
                            offsetCenter: ['0%', '-20%']
                        },
                        detail: {
                            valueAnimation: true,
                            offsetCenter: ['0%', '25%']
                        }
                    }
                ],
                title: {
                    fontSize: '1.3rem',
                    color: '#576E86',
                    fontWeight: 550
                },
                detail: {
                    width: 60,
                    height: 50,
                    fontSize: '2.0rem',
                    fontWeight: 800,
                    color: '#38A738',
                    borderColor: '#38A738',
                    borderRadius: 10,
                    borderWidth: 0,
                    formatter: '{value}%'
                }
            }
        ]
    };
    options && chartAssessment.setOption(options);
    window.addEventListener("resize", function () {
        if (chartAssessment != null && chartAssessment != undefined) {
            chartAssessment.resize();
        }
    });
}


var setCRGauge = function (data) {
    if (!document.getElementById("rating-cr")) return;
    if (isNaN(data.rating_cr_punteggio) || data.rating_cr_punteggio === null) {
        $(".rating-cr").addClass("rating-cr__no-data");
        return;
    }
    var myChart = echarts.init(document.getElementById("rating-cr"));
    var option = {
        series: [
            {
                type: "gauge",
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: 10,
                splitNumber: 8,
                radius: "125%",
                center: ["50%", "75%"],
                axisLine: {
                    lineStyle: {
                        width: 6,
                        color: [
                            [0.1, "#FF2100"],
                            [0.2, "#F96F16"],
                            [0.3, "#F9A531"],
                            [0.4, "#F9D230"],
                            [0.5, "#F7DE22"],
                            [0.6, "#D9DE38"],
                            [0.7, "#A6D13E"],
                            [0.8, "#83A800"],
                            [0.9, "#04B300"],
                            [1, "#007500"],
                        ],
                    },
                },
                pointer: {
                    icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                    length: "12%",
                    width: 20,
                    offsetCenter: [0, "-60%"],
                    itemStyle: {
                        color: "auto",
                    },
                },
                axisTick: {
                    length: 8,
                    lineStyle: {
                        color: "auto",
                        width: 2,
                    },
                },
                splitLine: {
                    length: 15,
                    lineStyle: {
                        color: "auto",
                        width: 4,
                    },
                },
                axisLabel: {
                    fontSize: 0,
                },
                title: {
                    offsetCenter: [0, "-20%"],
                    fontSize: 30,
                },
                detail: {
                    fontSize: 50,
                    offsetCenter: [0, "0%"],
                    valueAnimation: true,
                    color: "auto",
                },
                data: [
                    {
                        value: data.rating_cr_punteggio,
                    },
                ],
            },
        ],
    };
    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
};

if (window.userRole !== 'admin_plus' && window.userRole !== 'admin_cciaa')
    document.addEventListener("DOMContentLoaded", loadData);
