import * as echarts from "echarts/dist/echarts.js";
import $ from "jquery";

var loadData = function () {
    if (window.location.pathname === "/dashboard") {
        console.info('Load Data Chart MF');
        $.ajax({
            url: "/dashboard/getModeFinanceData",
            type: "GET",
            success: function (data) {
                console.info('Success: ',data);
                var response = data.data;
                console.info('Response data');
                console.debug(response);
                if (!_.isEmpty(response.final_rating_score))
                    setMainScoreParam(response.final_rating_score);
                if (response.distribution.length > 0)
                    setRatingParam(response.distribution);
               if (response.contribution.length > 0)
                    setContributionParam(response.contribution);
                if (!_.isEmpty(response.ams))
                    setMicroSectorParam(response.ams);
                if (response.previsioning.history.length > 0)
                    setPrevisionHParam(response.previsioning);
                if (response.previsioning.predicted.length > 0)
                    setPrevisionFParam(response.previsioning);
            },
            error: function (data) {
                // readd no data class
                console.info('Error: ',data);
                var response = data.data;
            },
        });
    }
}

var setMainScoreParam = function(finalScore){
    $('#valore_score').text(finalScore.final_rating);
    $('.company-mf-score-ms .company-mf-score-ms__container').css('background-color',finalScore.color);
    $(".company-mf-score-ms").removeClass("company-mf-score-ms__no-data");
}

var setRatingParam = function(distribution){
    var myChart = echarts.init(document.getElementById("company-rating"));

    var option = {
        xAxis: {
            type: 'category',
            data: distribution.map((a) => a.more_score_label)
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: distribution.map((a) => a.values),
                type: 'bar'
            }
        ]
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
    $(".company-distribution").removeClass("company-distribution__no-data");
}

var setMicroSectorParam = function(ams){
    $('.boxImpresa span').text(ams.company_name);
    for (let i = 0; i < ams.company_score.length; i++) {
        $('.box'+i+' .score').text(ams.company_score[i].score);
        $('.box'+i+' .titleMonth').html(ams.company_score[i].date);
        $('.box'+i+' .pointCircle').css('background-color',ams.company_score[i].color);
    }
    $(".company-score-ms__no-data").removeClass("company-score-ms__no-data");
}

var setContributionParam = function(contributions){
    var myChart = echarts.init(document.getElementById("company-contribution"));
    const dates = contributions.map((a) => a.values)
    const dateVal = dates.map((a) => a.value)
    console.info('dateVal: ',dateVal);
    var maxX = Math.max(...dateVal);
    maxX += (5 - maxX % 5);

    var option = {
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            min: 0,
            max: maxX,
            splitNumber: 5,
            interval: 5,
            axisLabel: {
                formatter: '{value} %'
            }

        },
        yAxis: {
            type: 'category',
            data: contributions.map((a) => a.label)
        },
        series: [
            {
                type: 'bar',
                data: contributions.map((a) => a.values),
            }
        ]
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
    $(".company-contribution-chart").removeClass("company-contribution__no-data");
}

/**
 * Next multiple of 10 for the given m number (Smallest n: n%10 === 0 AND n>m)
 * @param m
 * @returns {number}
 */
const nextTen = (m) => {
    return (m + (m < 0 ? 0 : 10)) - (m % 10);
}
/**
 * Previous multiple of 10 for the given m number (Biggest n: n%10 === 0 AND n<m)
 * @param m
 * @returns {number}
 */
const previousTen = (m) => {
    return m - (m%10) - (m<=0?10:0);
}

var setPrevisionHParam = function(prevision){
    var myChart = echarts.init(document.getElementById("company-prevision-h-ms"));
    const dates = prevision.history.map((a) => a.date);
    const revenues_history = prevision.history.map((a) => a.var_revenue);
    const revenues_predicted = prevision.predicted.map((a) => a.var_revenue);
    const revenues = [...revenues_history, ...revenues_predicted];

    const yMin = previousTen(Math.min(...revenues));
    const yMax = nextTen(Math.max(...revenues));

    var option = {
        title: {
            text: 'Andamento Storico',
            left: '1%'
        },
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '40px',
            width: '96%',
            bottom: '10%'
        },
        xAxis: {
            data: dates
        },
        yAxis: {
            min: yMin,
            max: yMax,
            splitNumber: 10,
            interval: 10,
            axisLabel: {
                formatter: '{value} %'
            }
        },
        toolbox: {
            show: false,
        },
        visualMap: {
            show : false,
            pieces: [
                {
                    gt: 0,
                    lte: yMax,
                    color: '#007DB3'
                },
                {
                    gt: yMin,
                    lte: 0,
                    color: '#D00015'
                }
            ],
            outOfRange: {
                color: '#999'
            }
        },
        series: {
            name: 'Andamento Storico',
            type: 'line',
            tooltip: {
                valueFormatter: value => value + ' %'
            },
            data: revenues_history
        }
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });

}

var setPrevisionFParam = function(prevision){
    var myChart = echarts.init(document.getElementById("company-prevision-f-ms"));

    const dates = prevision.predicted.map((a) => a.date);
    const revenues_history = prevision.history.map((a) => a.var_revenue);
    const revenues_predicted = prevision.predicted.map((a) => a.var_revenue);
    const revenues = [...revenues_history, ...revenues_predicted];

    const yMin = previousTen(Math.min(...revenues));
    const yMax = nextTen(Math.max(...revenues));
    var option = {
        title: {
            text: 'Andamento Previsionale',
            left: '1%'
        },
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '40px',
            width: '95%',
            bottom: '10%'

        },
        xAxis: {
            data: dates
        },
        yAxis: {
            min: yMin,
            max: yMax,
            splitNumber: 10,
            interval: 10,
            axisLabel: {
                formatter: '{value} %'
            }
        },
        series: {
            name: 'Andamento previsionale',
            type: 'line',
            data: revenues_predicted,
            areaStyle: {
                color: 'rgba(255, 147, 49, 0.5)'
            },
            lineStyle: {
                color: '#FF9331'
            },
            tooltip: {
                valueFormatter: value => value + ' %'
            }
        }
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
    $(".company-prevision__no-data").removeClass("company-prevision__no-data");
}

var _mappingScoreColor = [
    {
        'AAA': {
            'esa' : '#2e7d32',
            'rgba' : 'rgba(46, 125, 50, 0.7)'
        },
        'AA': {
            'esa' : '#4caf4f',
            'rgba' : 'rgba(76, 175, 80, 0.7)'
        },
        'A': {
            'esa' : '#9ccc0a',
            'rgba' : 'rgba(156, 204, 101, 0.7)'
        },
        'BBB': {
            'esa' : '#d3e157',
            'rgba' : 'rgba(212, 225, 87, 0.7)'
        },
        'BB': {
            'esa' : '#ffe733',
            'rgba' : 'rgba(255, 230, 51, 0.7)'
        },
        'B': {
            'esa' : '#ff9900',
            'rgba' : 'rgba(255, 153, 0, 0.7)'
        },
        'CCC': {
            'esa' : '#ffadad',
            'rgba' : 'rgba(255, 173, 173, 0.7)'
        },
        'CC': {
            'esa' : '#ff7070',
            'rgba' : 'rgba(255, 112, 112, 0.7)'
        },
        'C': {
            'esa' : '#ff0606',
            'rgba' : 'rgba(255, 6, 6, 0.7)'
        },
        'D': {
            'esa' : '#cc0000',
            'rgba' : 'rgba(204, 0, 0, 0.7)'
        },

    }
]


if (window.userRole !== 'admin_plus' && window.userRole !== 'admin_cciaa')
    document.addEventListener("DOMContentLoaded", loadData);
