import * as echarts from "echarts/dist/echarts.js";
import $ from "jquery";

const loadData = function () {
    if (window.location.pathname === "/dashboard")
        $.ajax({
            url: "/dashboard/getCciaaData",
            type: "GET",
            success: function (data) {
                setCompaniesPreferences(data.companies_preferences_data);
                $(".companies_preferences").removeClass(
                    "companies_preferences__no-data"
                );
                $(".companies_status").removeClass("companies_status__no-data");

                setCloseUp(data.closeup_data);
                $(".close-up").removeClass("close-up__no-data");

                setTotalDistributions(data.closeup_data.enabled_companies, data.tokens_data_totals);
                setCompaniesScoringDistributions(data.tokens_data_totals);
                setCompaniesWarningDistributions(data.tokens_data_totals);


            },
            error: function (data) {
                $("#esa_profilo").hide();
                $("#esa_profilo_base_null").show();
            },
        });
};

/**
 *
 * @param {int} data.warning_companies_count
 * @param {int} data.scoring_companies_count
 */
const setCompaniesPreferences = function (data) {
    if (!document.getElementById("cciaa-companies-preferences-pie")) return;
    const myChart = echarts.init(
        document.getElementById("cciaa-companies-preferences-pie")
    );
    const colorPalette = ["#8A9BAF", "#576e86"];
    const option = {
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            bottom: "bottom",
        },
        series: [
            {
                name: "Imprese",
                type: "pie",
                radius: "50%",
                color: colorPalette,
                data: [
                    {
                        name: "Check-Up",
                        value: data.warning_companies_count,
                        label: {
                            overflow: 'none'
                        }
                    },
                    {
                        name: "Scoring",
                        value: data.scoring_companies_count,
                        label: {
                            overflow: 'none'
                        }
                    },
                ],
            },
            {
                name: "Check-Up",
                type: "pie",
                radius: "50%",
                color: "#576e86",
                value: 158,
            },
            {
                name: "Scoring",
                type: "pie",
                radius: "50%",
                color: "#8A9BAF",
                value: 240,
            },
        ],
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
};

/**
 *
 * @param {int} enabled_companies
 * @param {object} totals
 * @param {int} totals.scoring_token
 * @param {int} totals.scoring_token_non_distribuiti
 * @param {int} totals.scoring_token_non_utilizzati
 * @param {int} totals.scoring_token_utilizzati
 * @param {int} totals.warning_token
 * @param {int} totals.warning_token_non_distribuiti
 * @param {int} totals.warning_token_non_utilizzati
 * @param {int} totals.warning_token_utilizzati
 */
const setTotalDistributions = function (enabled_companies, totals) {
    /**
     * set global value row title
     */
    $('#TotalScoringCredits').text(totals.scoring_token);
    $('#TotalWarningCredits').text(totals.warning_token);
    $('#TotalCompanies').text(enabled_companies); //id is total companies, but it represents number of cciaas
    /**
     * set scoring pie
     */
    const scoringTotalDist = totals.scoring_token_utilizzati + totals.scoring_token_non_utilizzati;
    const perScoring = (scoringTotalDist/totals.scoring_token)*100;
    $('#piePercBorderBox-perc-scoring').text(Number(perScoring).toLocaleString('it-IT',{minimumFractionDigits: 2, maximumFractionDigits:2})+'%');
    $('#piePercBorderBox-utilizzati-scoring').text(totals.scoring_token_utilizzati);
    /**
     * set warning pie
     */
    const warningTotalDist = totals.warning_token_utilizzati + totals.warning_token_non_utilizzati;
    const perWarning = (warningTotalDist/totals.warning_token)*100;
    $('#piePercBorderBox-perc-warning').text(Number(perWarning).toLocaleString('it-IT',{minimumFractionDigits: 2, maximumFractionDigits:2})+'%');
    $('#piePercBorderBox-utilizzati-warning').text(totals.warning_token_utilizzati);

}

/**
 *
 * @param {object} totals
 * @param {int} totals.scoring_token
 * @param {int} totals.scoring_token_non_distribuiti
 * @param {int} totals.scoring_token_non_utilizzati
 * @param {int} totals.scoring_token_utilizzati
 * @param {int} totals.warning_token
 * @param {int} totals.warning_token_non_distribuiti
 * @param {int} totals.warning_token_non_utilizzati
 * @param {int} totals.warning_token_utilizzati
 */
const setCompaniesScoringDistributions = function (totals) {
    if (!document.getElementById("scoring-ente-distribution-pie")) return;
    const myChart = echarts.init(
        document.getElementById("scoring-ente-distribution-pie")
    );
    const colorPalette = ["#576e86", "#63809e", "#eaeff5"];

    const option = {
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            bottom: "bottom",
        },
        series: [
            {
                name: "Imprese",
                type: "pie",
                radius: "65%",
                color: colorPalette,
                data: [
                    {
                        name: "Distribuiti utilizzati",
                        value: totals.scoring_token_utilizzati,
                        label: {
                            overflow: "none",
                        },
                    },
                    {
                        name: "Distribuiti non utilizzati",
                        value: totals.scoring_token_non_utilizzati,
                        label: {
                            overflow: "none",
                        },
                    },
                    {
                        name: "Non distribuiti",
                        value: totals.scoring_token_non_distribuiti,
                        label: {
                            overflow: "none",
                        },
                    },
                ],
            }
        ],
        media: [
            {
                query: {
                    maxAspectRatio: 1, // when length-to-width ratio is less than 1
                },
            },
        ],
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
};

/**
 *
 * @param {object} totals
 * @param {int} totals.scoring_token
 * @param {int} totals.scoring_token_non_distribuiti
 * @param {int} totals.scoring_token_non_utilizzati
 * @param {int} totals.scoring_token_utilizzati
 * @param {int} totals.warning_token
 * @param {int} totals.warning_token_non_distribuiti
 * @param {int} totals.warning_token_non_utilizzati
 * @param {int} totals.warning_token_utilizzati
 */
const setCompaniesWarningDistributions = function (totals) {

    /**
     * set pie chart values
     */
    if (!document.getElementById("warning-ente-distribution-pie")) return;
    const myChart = echarts.init(
        document.getElementById("warning-ente-distribution-pie")
    );
    const colorPalette = ["#8a9baf", "#adc2da", "#eaeff5"];

    const option = {
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            bottom: "bottom",
        },
        series: [
            {
                name: "Imprese",
                type: "pie",
                radius: "65%",
                color: colorPalette,
                data: [
                    {
                        name: "Distribuiti utilizzati",
                        value: totals.warning_token_utilizzati,
                        label: {
                            overflow: "none",
                        },
                    },
                    {
                        name: "Distribuiti non utilizzati",
                        value: totals.warning_token_non_utilizzati,
                        label: {
                            overflow: "none",
                        },
                    },
                    {
                        name: "Non distribuiti",
                        value: totals.warning_token_non_distribuiti,
                        label: {
                            overflow: "none",
                        },
                    },
                ],
            }
        ],
        media: [
            {
                query: {
                    maxAspectRatio: 1, // when length-to-width ratio is less than 1
                },
            },
        ],
    };

    myChart.setOption(option);
    window.addEventListener("resize", function () {
        if (myChart != null && myChart != undefined) {
            myChart.resize();
        }
    });
};

/**
 * @param {int} data.max_allowed_companies
 * @param {int} data.enabled_companies
 * @param {int} data.companies_logged_at_least_once
 * @param {int} data.companies_with_email_verified
 * @param {int} data.companies_with_at_least_one_service
 * @param {int} data.companies_with_all_service
 * @param {int} data.scoring_companies
 * @param {int} data.warning_companies
 */
const setCloseUp = function (data) {
    const max_allowed_companies = data.max_allowed_companies;
    $("#max_allowed_companies").text(max_allowed_companies);
    $("#max_companies").text(max_allowed_companies);

    const enabledCompanies = data.enabled_companies;
    $("#notEnabledCompanies").text(
        max_allowed_companies - enabledCompanies
    );
    $("#enabledCompanies").text(enabledCompanies);
    $("#enabledCompaniesTotal").text(enabledCompanies);
    $("#lastloginCompaniesTotal").text(enabledCompanies);
    $("#emailverifiedCompaniesTotal").text(enabledCompanies);

    const lastloginCompanies = data.companies_logged_at_least_once;
    $("#lastloginCompanies").text(lastloginCompanies);

    const emailverifiedCompanies = data.companies_with_email_verified ;
    $("#emailverifiedCompanies").text(emailverifiedCompanies);

    const oneServiceCompanies = data.companies_with_at_least_one_service;
    $("#oneServiceCompanies").text(oneServiceCompanies);
    $("#oneServiceCompaniesTotal").text(oneServiceCompanies);
    $("#oneServiceCompaniesPercentage").text(
        (oneServiceCompanies / enabledCompanies) * 100 + "%"
    );

    const allServiceCompanies = data.companies_with_all_service;
    $("#allServiceCompanies").text(allServiceCompanies);
    $("#allServiceCompaniesPercentage").text(
        (allServiceCompanies / oneServiceCompanies) * 100 + "%"
    );

    const scoringCompanies = data.scoring_companies;
    const warningCompanies = data.warning_companies;
    $("#scoringCompanies").text(scoringCompanies);
    $("#earlyWarningCompanies").text(warningCompanies);

    const percScoring = Math.round(
        (
            (scoringCompanies * 100) /
            (enabledCompanies)
        )
    ) ;
    const percWarning = Math.round(
        (
            (warningCompanies * 100) /
            (enabledCompanies)
        )
    ) ;

    $("#scoringCompaniesPercentage").text(
        (isNaN(percScoring) ? '0' : percScoring) + "%"
    );
    $("#earlyWarningCompaniesPercentage").text(
        (isNaN(percWarning) ? '0' : percWarning) + "%"
    );
};
if (window.userRole === 'admin_cciaa')
document.addEventListener("DOMContentLoaded", loadData);
