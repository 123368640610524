var initCompanyIndex = function () {
    $('select', '#cciaa-filter-wrap').on("change", function(event) {
        var selectedLabel = $('option:selected', this).attr('data-full-name');
        var selectedValue = $('option:selected', this).val();

        var tooltipElem = document.getElementById('cciaa-filter-wrap');

        var tooltipInstance = M.Tooltip.getInstance(tooltipElem);
        tooltipInstance.destroy()

        var tooltipText = (selectedValue == '') ? "Nessun ente selezionato" : selectedLabel;
        $(tooltipElem).attr('data-tooltip', tooltipText);
        M.Tooltip.init(tooltipElem);
    });

    /**
     * Scorign MF
     */
    $('#serviceActivateBtn').on('click',function(e) {
        $(this).prop('disabled','disabled');
    });

    $('#serviceUploadBtn').on('click',function(e) {
        $(this).prop('disabled','disabled');
    });

};

document.addEventListener("DOMContentLoaded", initCompanyIndex);
