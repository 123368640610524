var initLegalDocumentsCreate = function () {

    $('form', '.legal-documents-create').submit(function(submitEvent){
        submitEvent.preventDefault();
        var form = this;
        // console.log('form submit called!')
        // form.submit();

        var confirmModal = $('#legal-documents-create__confirm-modal');
        var confirmModalCancelButton = $('#legal-documents-create__confirm-modal-cancel');
        var confirmModalOkButton = $('#legal-documents-create__confirm-modal-ok');

        confirmModal.modal('open');
        confirmModalCancelButton.on('click', function(cancelEvent) {
            cancelEvent.preventDefault();
            confirmModal.modal('close');
        });

        confirmModalOkButton.on('click', function(okEvent) {
            okEvent.preventDefault();
            form.submit();
        });
    });

    $('form [name="attachment"]').on('change',function(){
        var extension=$('#file_upload').val().replace(/^.*\./, '');
    });
};

document.addEventListener("DOMContentLoaded", initLegalDocumentsCreate);
