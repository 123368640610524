require("./bootstrap");
require("materialize-css");
import "./application/init_admin_cciaa_echarts";
import "./application/init_admin_plus_echarts";
import "./application/init_collapsible_materialize";
import "./application/init_datepicker_materialize";
import "./application/init_dropdown_materialize";
import "./application/init_modal_materialize";
import "./application/init_tooltip_materialize";
import "./application/init_province_autocomplete";
import "./application/init_select_materialize";
import "./application/init_sidenav_materialize";
import "./application/init_tabs_materialize";
import "./application/load_ghost_cfo_data_user_echarts";
import "./application/load_innolva_data_user_echarts";
import "./application/load_mf_data_user_echarts";
import "./application/company_index";
import "./application/legal_documents_create";
import "./application/custom_shared";
import "./application/index_person";
