import $ from "jquery";

$(function () {
    $(".modal").not(".no-location-reload").modal({
        dismissible: false,
        onOpenEnd: function () {
            location.hash = $(this).prop("id");
        },
        onCloseEnd: function () {
            location.hash = "";
            window.location.reload();
        },
    });

    $(".modal.no-location-reload").modal({
        dismissible: false
    });

});
