var initCollapsible = function () {
    var elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems, {
        onOpenStart: function () {
            arrow = document.querySelector("#collapsible_arrow");
            if (!arrow) return;
            arrow.classList.remove("fa-chevron-right");
            arrow.classList.add("fa-chevron-down");
        },
        onCloseEnd: function () {
            arrow = document.querySelector("#collapsible_arrow");
            if (!arrow) return;
            arrow.classList.remove("fa-chevron-down");
            arrow.classList.add("fa-chevron-right");
        },
    });
};

document.addEventListener("DOMContentLoaded", initCollapsible);
